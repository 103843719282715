import React from "react"
import ImageGallery from "react-image-gallery"

const images = [
  {
    original:
      "https://lh3.googleusercontent.com/pw/ACtC-3cAENdp34l3zEU-6vVDHZfmXIStbbN6EUwns3sN78NDleUMVXynzD1Ijn6i_wk88EriZckGCbqlt45shhhLeWStRKdLcEZs9plR5EV026K-RspdBhFUy9ZfZDSHy1H8CK_SN8j9lwlmd7_YQpvXFcV3=d",
    thumbnail:
      "https://lh3.googleusercontent.com/pw/ACtC-3cAENdp34l3zEU-6vVDHZfmXIStbbN6EUwns3sN78NDleUMVXynzD1Ijn6i_wk88EriZckGCbqlt45shhhLeWStRKdLcEZs9plR5EV026K-RspdBhFUy9ZfZDSHy1H8CK_SN8j9lwlmd7_YQpvXFcV3=d",
  },
  {
    original:
      "https://lh3.googleusercontent.com/pw/ACtC-3ctO3E9-4j3uslX-XMVHzAVUnykxKG9MI-E9VBC90nLK57a5qO2B4bTlH02sy-0V09X0xHz2XLdYNuLiouJ9nrB1v5y0MPKKvoxS81V11YODmYzqXF2F3OHEhnAGXL5LmxAKS887eQZohvEzWp0HDcS=d",
    thumbnail:
      "https://lh3.googleusercontent.com/pw/ACtC-3ctO3E9-4j3uslX-XMVHzAVUnykxKG9MI-E9VBC90nLK57a5qO2B4bTlH02sy-0V09X0xHz2XLdYNuLiouJ9nrB1v5y0MPKKvoxS81V11YODmYzqXF2F3OHEhnAGXL5LmxAKS887eQZohvEzWp0HDcS=d",
  },
  {
    original:
      "https://lh3.googleusercontent.com/pw/ACtC-3eHmCy-N2ElqUd-SB4Gkz7m3T8bAF3ARXGlG3rMcxTA7VZXbwqSeJKOIO1iQl_dBemzlqdrtwHho3-Zs4enCP57lWP2KjBHLHbN9QeoDmESdqy0o5INDqXjHk4revpnXA1OT70NnFkV6ffjPiBnweCv=d",
    thumbnail:
      "https://lh3.googleusercontent.com/pw/ACtC-3eHmCy-N2ElqUd-SB4Gkz7m3T8bAF3ARXGlG3rMcxTA7VZXbwqSeJKOIO1iQl_dBemzlqdrtwHho3-Zs4enCP57lWP2KjBHLHbN9QeoDmESdqy0o5INDqXjHk4revpnXA1OT70NnFkV6ffjPiBnweCv=d",
  },
  {
    original:
      "https://lh3.googleusercontent.com/pw/ACtC-3cYih9X6ar-s2i7sCW9HSOlCfji2epqgal4DjUhCA_9MuVXkwneMCB_2w51iwcCosh2dWpWY_ObPGRYsY7nQfB6FKj4rSfeDaRYrArRVCrHmtHG9I1pcwSvz8JRYtU7Pp8zr4_2gHfvLjCZqw6CUUE=d",
    thumbnail:
      "https://lh3.googleusercontent.com/pw/ACtC-3cYih9X6ar-s2i7sCW9HSOlCfji2epqgal4DjUhCA_9MuVXkwneMCB_2w51iwcCosh2dWpWY_ObPGRYsY7nQfB6FKj4rSfeDaRYrArRVCrHmtHG9I1pcwSvz8JRYtU7Pp8zr4_2gHfvLjCZqw6CUUE=d",
  },
  {
    original:
      "https://lh3.googleusercontent.com/pw/ACtC-3dBYmQ_IL5_XMQcekGANMlGGZTZKTDfbOVETqQTy-JuDL43RtfUZdjTUjL0GabVegF-9doVter2wForeuHr3JvtBARceAGpigHBIICO81gXVtTq6gS27pYWf8fUEBdUKXUH9VdJC23I1o26pr_6MUY=d",
    thumbnail:
      "https://lh3.googleusercontent.com/pw/ACtC-3dBYmQ_IL5_XMQcekGANMlGGZTZKTDfbOVETqQTy-JuDL43RtfUZdjTUjL0GabVegF-9doVter2wForeuHr3JvtBARceAGpigHBIICO81gXVtTq6gS27pYWf8fUEBdUKXUH9VdJC23I1o26pr_6MUY=d",
  },
  {
    original:
      "https://lh3.googleusercontent.com/pw/ACtC-3ex8_skW4XHOtf5TSC4LbpLuVnR-ThhfiVj0qqai_3WhwTuDsVyL7ddnxZKOzDYxDaqYuFsA3p54kySq90EOjrxIJjMVtjS1zpFTJzQEn5XqLwNMMaUQEHVTKlfcC5Uf_pm_vSkdsUygjltJcm7SIA=d",
    thumbnail:
      "https://lh3.googleusercontent.com/pw/ACtC-3ex8_skW4XHOtf5TSC4LbpLuVnR-ThhfiVj0qqai_3WhwTuDsVyL7ddnxZKOzDYxDaqYuFsA3p54kySq90EOjrxIJjMVtjS1zpFTJzQEn5XqLwNMMaUQEHVTKlfcC5Uf_pm_vSkdsUygjltJcm7SIA=d",
  },
  {
    original:
      "https://lh3.googleusercontent.com/pw/ACtC-3d1UsD2B7b-S4JUUk_IEDaHsWWot5aZ4O0MZxBCc9N9GvMuC6LzMABBDSy1H48ua2aM3NbNcMf1zhwI53CRb92EUX8LJM2MbqojzD6gdPWzq12AozW5g1QFmJXO0_TNG1JabEXg8FoYmXjLY_jUXTk=d",
    thumbnail:
      "https://lh3.googleusercontent.com/pw/ACtC-3d1UsD2B7b-S4JUUk_IEDaHsWWot5aZ4O0MZxBCc9N9GvMuC6LzMABBDSy1H48ua2aM3NbNcMf1zhwI53CRb92EUX8LJM2MbqojzD6gdPWzq12AozW5g1QFmJXO0_TNG1JabEXg8FoYmXjLY_jUXTk=d",
  },
  {
    original:
      "https://lh3.googleusercontent.com/pw/ACtC-3fweeJLHQO0FYf2FXuIYgSHI7mqGx3bBr_d8Grl_x2BVZIiGKxN6PVRPIBvWznPFfJ-c3YVxwnczA9sfOuaLgtqjLgAnssxT7gFz5bf3LN0Mnk_mA3Tx2Wn9iNtvA4s_I5T_vXmMcbPz54tMa-TDro=d",
    thumbnail:
      "https://lh3.googleusercontent.com/pw/ACtC-3fweeJLHQO0FYf2FXuIYgSHI7mqGx3bBr_d8Grl_x2BVZIiGKxN6PVRPIBvWznPFfJ-c3YVxwnczA9sfOuaLgtqjLgAnssxT7gFz5bf3LN0Mnk_mA3Tx2Wn9iNtvA4s_I5T_vXmMcbPz54tMa-TDro=d",
  },
  {
    original:
      "https://lh3.googleusercontent.com/pw/ACtC-3e-2sO3C-mvJO5hY2cubumG7sJjtENwoxkgrBWRrZkcyjmK_3JjR1TnMoCTrs20SMO6CStbqZ4P-xMPFxLTNacodc0ti-pgUGO0WtInD1FYoYP4bEvpsU1olaV-tuqk-RaC1zpuw2mVWolmFz6-_6s=d",
    thumbnail:
      "https://lh3.googleusercontent.com/pw/ACtC-3e-2sO3C-mvJO5hY2cubumG7sJjtENwoxkgrBWRrZkcyjmK_3JjR1TnMoCTrs20SMO6CStbqZ4P-xMPFxLTNacodc0ti-pgUGO0WtInD1FYoYP4bEvpsU1olaV-tuqk-RaC1zpuw2mVWolmFz6-_6s=d",
  },
]

class MyGallery extends React.Component {
  render() {
    return (
      <ImageGallery
        style={{ maxHeight: "200px" }}
        showThumbnails={false}
        useBrowserFullscreen={false}
        items={images}
        autoPlay={true}
        showBullets={true}
        showNav={false}
        showPlayButton={false}
        showFullscreenButton={false}
      />
    )
  }
}

export default MyGallery
